/* ANIMATED */

/* #jound {
  display: none;
}

#mca {
  display: none;
} */

body {
  overflow: hidden;
}

#hypebeastDisplay {
}

* {
  margin: 0px;
  padding: 0px;
}

.work-in-progress {
  text-align: center;
  font-size: 24px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  background-color: black;
  opacity: 0.5;
  z-index: 1000;
}

.overlay-text {
  position: absolute;
  top: 20vw;
  left: 0;
  width: 100%;
  height: 100%;
  color: white !important;
}

.nav {
  position: absolute;
  z-index: 999;
  margin-top: 2vh;
}

.grid-item {
  margin-top: 12vh;
  margin-left: 5vw;
  font-size: 36px;
  cursor: none;
}

.grid-item:hover {
  opacity: 30%;
  transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -webkit-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
}

.hypebeast-img {
  height: 90vh;
  /* animation: fadeInRight;
  animation-duration: 15s; */
}

.nav-img-1 {
  height: 90vh;
  animation: fadeInRight;
  animation-duration: 3s;
}

.nav-img-2 {
  height: 90vh;
  animation: fadeIn;
  animation-duration: 3s;
}

.nav-img-3 {
  height: 90vh;
  animation: fadeIn;
  animation-duration: 3s;
}

.nav-img-div {
  margin-left: 18vw;
}

/* ABOUT */

#about {
  display: none;
}

.about-contain {
  margin-left: 5vw;
  overflow: hidden;
}

.about {
  width: 60%;
}

.about-header {
  text-align: left;
  font-size: 60px;
  margin: 0;
  padding-bottom: 2vh;
}

.about-sub-container {
  display: flex;
  flex-flow: row;
  text-align: right;
  width: 150%;
  border-left: 1px solid black;
  align-items: flex-start;
}

.about-sub {
  flex: 1 0 50%;
  text-align: left;
  line-height: 3vh;
  margin-left: 1vw;
  padding-left: 0.5vw;
  background-color: rgba(255, 255, 255, 0.9);
}

.about-sub-right {
  flex: 1 0 50%;
  text-align: left;
  line-height: 3vh;
  padding-left: 1.5vw;
  margin-left: 1.5vw;
}

/* ABOUT */

/* TESTIMONIALS */

#test {
  display: none;
}

.test-contain {
  margin-left: 35vw;
}

.test {
  width: 100%;
}

.test-header {
  text-align: left;
  font-size: 60px;
  margin: 0;
  padding-bottom: 2vh;
}

.test-sub-container {
  text-align: center;
}

.test-sub {
  text-align: left;
  line-height: 3vh;
  padding-top: 0.6vw;
  padding-bottom: 0.4vw;
  padding-left: 0.5vw;
  border: 1px solid black;
  margin-bottom: 4%;
  max-width: 56vh !important;
}

.test-name-container {
  display: inline-block;
  margin: 0;
}

.test-name {
  vertical-align: top;
  display: inline;
  margin: 0;
  font-size: 18px;
}

.test-handle {
  vertical-align: top;
  display: inline;
  margin: 0;
  opacity: 50%;
  font-weight: 400;
  font-size: 18px;
}

.test-description {
  vertical-align: bottom;
  display: inline;
  margin: 0;
}

.test-img {
  width: 50px;
  display: inline-block;
  border-radius: 100%;
}

.test-img-col {
  width: 10% !important;
  max-width: 10vh !important;
}

.test-ref-col {
  padding: 0 !important;
}

.test-ref {
  padding: 0 !important;
}

/* TESTIMONIALS */

/* SALES */

#sales {
  display: none;
}

/* FUTURE */

#future {
  display: none;
}

.future-contain {
  margin-left: 35vw;
  overflow: hidden;
}

.future {
  width: 60%;
}

.future-header {
  text-align: left;
  font-size: 60px;
  margin: 0;
  padding-bottom: 2vh;
}

.future-sub-container {
  display: flex;
  flex-flow: row;
  text-align: right;
  width: 150%;
  border-left: 1px solid black;
  align-items: flex-start;
}

.future-sub {
  flex: 1 0 50%;
  text-align: left;
  line-height: 3vh;
  margin-left: 1vw;
  padding-left: 0.5vw;
}

/* ABOUT */

/* CUSTOM BOOTSTRAP */

.container {
  margin-left: 0 !important;
}

/* PRESETS */

.p-animate-delay-1 {
  animation-delay: 100ms;
}

.p-animate-delay-2 {
  animation-delay: 200ms;
}

.p-animate-delay-3 {
  animation-delay: 300ms;
}

.p-animate-delay-4 {
  animation-delay: 400ms;
}

.p-animate-delay-5 {
  animation-delay: 500ms;
}

/* SNEAKER BACKGROUND */

/* MCA */

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 39.75px;
  height: 47.6px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  background: url(./img/mcafloat.png) 30%;
  background-size: contain;
}

.circles li:nth-child(1) {
  left: 60%;
  width: 39.75px;
  height: 47.6px;
  animation-delay: 12s;
  animation-duration: 60s;
  background: url(./img/mcafloatflipped.png) 30%;
  background-size: contain;
}

.circles li:nth-child(6) {
  left: 63%;
  width: 39.75px;
  height: 47.6px;
  animation-delay: 14s;
  animation-duration: 60s;
  background: url(./img/mcafloat.png) 30%;
  background-size: contain;
}

.circles li:nth-child(2) {
  left: 70%;
  width: 79.5px;
  height: 95.3px;
  animation-delay: 2s;
  animation-duration: 45s;
  background: url(./img/mcafloat.png) 30%;
  background-size: contain;
}

.circles li:nth-child(3) {
  left: 85%;
  width: 159px;
  height: 190.6px;
  animation-delay: 0s;
  animation-duration: 30s;
  background: url(./img/mcafloat.png) 30%;
  background-size: contain;
}

.circles li:nth-child(4) {
  left: 75%;
  width: 159px;
  height: 190.6px;
  animation-delay: 0s;
  animation-duration: 18s;
  background: url(./img/mcafloatflipped.png) 30%;
  background-size: contain;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 79.5px;
  height: 95.3px;
  animation-delay: 7s;
  background: url(./img/mcafloatflipped.png) 60%;
  background-size: contain;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 20;
  }

  100% {
    transform: translateY(-1000px) rotate(120deg);
    opacity: 0;
    border-radius: 10%;
  }
}

/* JOUND */
#joundFloat {
  display: none;
}

.jound-float {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.jound-float li {
  position: absolute;
  display: block;
  list-style: none;
  width: 30px;
  height: 26.25px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  background: url(./img/joundfloat.png) 30%;
  background-size: contain;
}

.jound-float li:nth-child(1) {
  left: 60%;
  width: 30px;
  height: 26.25px;
  animation-delay: 15s;
  animation-duration: 60s;
  background: url(./img/joundfloatflipped.png) 30%;
  background-size: contain;
}

.jound-float li:nth-child(2) {
  left: 63%;
  width: 30px;
  height: 26.25px;
  animation-delay: 24s;
  animation-duration: 60s;
  background: url(./img/joundfloat.png) 30%;
  background-size: contain;
}

.jound-float li:nth-child(3) {
  left: 75%;
  width: 120px;
  height: 105px;
  animation-delay: 0s;
  animation-duration: 15s;
  background: url(./img/joundfloatflipped.png) 30%;
  background-size: contain;
}

.jound-float li:nth-child(4) {
  left: 85%;
  width: 120px;
  height: 105px;
  animation-delay: 0s;
  animation-duration: 30s;
  background: url(./img/joundfloat.png) 30%;
  background-size: contain;
}

.jound-float li:nth-child(5) {
  left: 65%;
  width: 60px;
  height: 52.5px;
  animation-delay: 7s;
  background: url(./img/joundfloatflipped.png) 60%;
  background-size: contain;
}

.jound-float li:nth-child(6) {
  left: 70%;
  width: 60px;
  height: 52.5px;
  animation-delay: 2s;
  animation-duration: 45s;
  background: url(./img/joundfloat.png) 30%;
  background-size: contain;
}

/* JORDAN */
#jordanFloat {
  display: none;
}

.jordan-float {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.jordan-float li {
  position: absolute;
  display: block;
  list-style: none;
  width: 29.8125px;
  height: 41.5px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  background: url(./img/jordanfloat.png) 30%;
  background-size: contain;
}

.jordan-float li:nth-child(1) {
  left: 60%;
  width: 29.8125px;
  height: 41.5px;
  animation-delay: 15s;
  animation-duration: 60s;
  background: url(./img/jordanfloatflipped.png) 30%;
  background-size: contain;
}

.jordan-float li:nth-child(6) {
  left: 63%;
  width: 29.8125px;
  height: 41.5px;
  animation-delay: 24s;
  animation-duration: 60s;
  background: url(./img/jordanfloat.png) 30%;
  background-size: contain;
}

.jordan-float li:nth-child(2) {
  left: 70%;
  width: 59.625px;
  height: 83px;
  animation-delay: 2s;
  animation-duration: 45s;
  background: url(./img/jordanfloat.png) 30%;
  background-size: contain;
}

.jordan-float li:nth-child(3) {
  left: 85%;
  width: 119.25px;
  height: 166px;
  animation-delay: 0s;
  animation-duration: 30s;
  background: url(./img/jordanfloat.png) 30%;
  background-size: contain;
}

.jordan-float li:nth-child(4) {
  left: 75%;
  width: 119.25px;
  height: 166px;
  animation-delay: 0s;
  animation-duration: 15s;
  background: url(./img/jordanfloatflipped.png) 30%;
  background-size: contain;
}

.jordan-float li:nth-child(5) {
  left: 65%;
  width: 59.625px;
  height: 83px;
  animation-delay: 7s;
  background: url(./img/jordanfloatflipped.png) 60%;
  background-size: contain;
}

/* AZAEL */
#azaelFloat {
  display: none;
}

.azael-float {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.azael-float li {
  position: absolute;
  display: block;
  list-style: none;
  width: 29.8125px;
  height: 41.5px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  background: url(./img/azaelfloat.png) 30%;
  background-size: contain;
}

.azael-float li:nth-child(1) {
  left: 60%;
  width: 29.8125px;
  height: 41.5px;
  animation-delay: 15s;
  animation-duration: 60s;
  background: url(./img/azaelfloatflipped.png) 30%;
  background-size: contain;
}

.azael-float li:nth-child(6) {
  left: 63%;
  width: 29.8125px;
  height: 41.5px;
  animation-delay: 24s;
  animation-duration: 60s;
  background: url(./img/azaelfloat.png) 30%;
  background-size: contain;
}

.azael-float li:nth-child(2) {
  left: 70%;
  width: 59.625px;
  height: 83px;
  animation-delay: 2s;
  animation-duration: 45s;
  background: url(./img/azaelfloat.png) 30%;
  background-size: contain;
}

.azael-float li:nth-child(3) {
  left: 85%;
  width: 119.25px;
  height: 166px;
  animation-delay: 0s;
  animation-duration: 30s;
  background: url(./img/azaelfloat.png) 30%;
  background-size: contain;
}

.azael-float li:nth-child(4) {
  left: 75%;
  width: 119.25px;
  height: 166px;
  animation-delay: 0s;
  animation-duration: 15s;
  background: url(./img/azaelfloatflipped.png) 30%;
  background-size: contain;
}

.azael-float li:nth-child(5) {
  left: 65%;
  width: 59.625px;
  height: 83px;
  animation-delay: 7s;
  background: url(./img/azaelfloatflipped.png) 60%;
  background-size: contain;
}

.pagination {
  width: 95%;
  margin: auto;
}

.pagination li {
  display: block !important;
  margin: auto !important;
  text-align: center !important;
}

.pagination-div {
}

.extras-container {
  position: relative;
  top: 2vw;
  left: 20vw;
}

.extras {
}

/* MOBILE */
/* MOBILE */
/* MOBILE */

@media screen and (max-width: 1024px), screen and (max-height: 1366px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    /* border: 5px dashed red; */
    display: flex;
    flex-flow: column;
    scroll-behavior: smooth;
  }

  .float-tabs {
    position: fixed;
    transition: all ease 1s;
    z-index: 3;
    text-align: left;
  }

  .div-tabs {
    opacity: 1;
    background-color: grey;
    width: min-content;
  }

  #about-tab {
  }

  #about-tab h1 {
    width: min-content;
    transition: ease all 1s;
  }

  #about-m {
    opacity: 1;
    transition: ease all 1s;
  }

  #testimonials-m {
    opacity: 0;
    transition: ease all 1s;
  }

  #sales-m {
    opacity: 0;
    transition: ease all 1s;
  }

  #future-m {
    opacity: 0;
    transition: ease all 1s;
  }

  /* #about-m:hover {
    opacity: 1;
    transition: ease all 1s;
  }

  #testimonials-m:hover {
    opacity: 1 !important;
    transition: ease all 1s;
  }

  #sales-m:hover {
    opacity: 1 !important;
    transition: ease all 1s;
  }

  #future-m:hover {
    opacity: 1 !important;
    transition: ease all 1s;
  } */

  .container-m {
    /* border: 5px dashed red; */
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: left;
  }

  .container-m h1 {
    font-size: 4vh;
    /* border: 5px dashed red; */
    margin: auto;
  }

  .titles-m {
    padding-left: 4%;
  }

  .titles-m h1 {
    font-size: 6vh;
  }

  /* Main */

  .mini-img {
    /* border: 1px dashed blueviolet; */
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -2;
    width: 100%;
  }

  .header-m {
    flex-grow: 2;
  }

  /* ABOUT */

  .articles-left-m {
    flex-grow: 1;
    margin-left: 4%;
  }

  .about-sub-m {
    border-left: 1px solid rgb(23, 50, 126);
    font-weight: 600;
    padding: 2% 4%;
    width: 85%;
    background-color: rgba(175, 194, 245, 0.5);
    /* background: linear-gradient(rgba(175, 194, 245, 0.719), rgba(0, 0, 0, 0)); */
    font-size: 2vh;
    text-align: left;
  }

  #joundM {
    display: block;
  }

  #aboutM {
    display: block;
  }

  /* TESTIMONIALS */

  #testBox0m {
    display: none;
  }

  #testBox1m {
    display: none;
  }

  #testBox2m {
    display: none;
  }

  #testBox3m {
    display: none;
  }

  #testBox4m {
    display: none;
  }

  .test-center-m {
    margin: auto;
    width: 90%;
  }

  .vert-m {
    position: absolute;
    top: 160%;
  }

  .test-main-m {
    margin-left: 4%;
    height: 60vh;
    /* border: 4px dashed brown; */
    overflow: auto;
  }

  .test-sub-m {
    display: flex;
    flex-flow: column;
    overflow: auto;
    /* border: 3px dashed yellow; */
    overflow: auto;
    border: 1px solid grey;
    padding: 6%;
    z-index: 2;
    text-align: left;
    line-height: 3vh;
    padding-top: 0.6vw;
    padding-bottom: 0.4vw;
    margin-bottom: 4%;
  }

  .test-name-m {
    vertical-align: top;
    display: inline;
    margin: 0;
    font-size: 2vh;
  }

  .test-handle-m {
    vertical-align: top;
    display: inline;
    margin: 0;
    opacity: 50%;
    font-weight: 400;
    font-size: 2vh;
  }

  .test-img-m {
    width: 65%;
    display: inline-block;
    border-radius: 100%;
    background: url(https://picsum.photos/500);
  }

  .test-img-col-m {
    height: 100% !important;
    padding: 2% 0;
    width: 22% !important;
  }

  .test-ref-col-m {
    padding: 0 !important;
    width: 78% !important;
  }

  /* SALES */

  .purchases-m {
    padding: 0 5%;
    font-size: 4vw;
  }
  .purchases-m div {
    display: inline-block;
  }

  .purchases-m h3 {
    text-align: left;
    font-size: 8vw;
  }

  /* FUTURE */

  .articles-right-m {
    z-index: 3;
    flex-grow: 1;
    margin-right: 2vw;
    display: flex;
  }

  .future-container-m {
    text-align: right;
    display: inline-block;
    margin-left: 4%;
  }

  .future-sub-m {
    border-left: 1px solid rgb(35, 126, 23);
    font-weight: 600;
    padding: 2% 4%;
    width: 85%;
    background-color: rgba(189, 245, 175, 0.5);
    /* background: linear-gradient(rgba(189, 245, 175, 0.719), rgba(0, 0, 0, 0)); */
    font-size: 2vh;
    text-align: left;
  }

  .footer-m {
    padding: 2% 0%;
    text-align: left;
    width: 85%;
    opacity: 0.5;
    font-size: 2vh;
    margin-left: 4%;
  }

  /* Images */

  .mcafloat-m {
    position: fixed;
    width: 25%;
    left: 0;
    margin: 15% 0 0 4%;
    transition: all ease 1s;
    margin-right: 2%;
  }

  .joundfloat-m {
    opacity: 0;
    position: fixed;
    width: 25%;
    left: 0;
    margin: 15% 0 0 4%;
    transition: all ease 1s;
    margin-right: 2%;
  }

  .jordanfloat-m {
    opacity: 0;
    position: fixed;
    width: 25%;
    left: 0;
    margin: 15% 0 0 4%;
    transition: all ease 1s;
    z-index: 2;
    margin-right: 2%;
  }

  .azaelfloat-m {
    opacity: 0;
    position: fixed;
    width: 25%;
    left: 0;
    margin: 15% 0 0 4%;
    transition: all ease 1s;
    z-index: 2;
    margin-right: 2%;
  }

  .container-test-m {
    width: 85%;
  }
}

@media screen and (max-width: 1024px), screen and (max-height: 1366px) {
  .mca-quote {
    writing-mode: vertical-rl;
    color: grey;
    height: 93%;
    font-size: 2vh;
    opacity: 1;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1% 1%;
    transition: ease all 1s;
  }

  .jound-quote {
    writing-mode: vertical-rl;
    color: grey;
    height: 93%;
    font-size: 2vh;
    opacity: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1% 1%;
    transition: ease all 1s;
  }

  .jordan-quote {
    writing-mode: vertical-rl;
    color: grey;
    height: 93%;
    font-size: 2vh;
    opacity: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1% 1%;
    transition: ease all 1s;
    z-index: 3;
  }

  .azael-quote {
    writing-mode: vertical-rl;
    color: grey;
    height: 93%;
    font-size: 2vh;
    opacity: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1% 1%;
    transition: ease all 1s;
    z-index: 3;
  }
}
